<template>
    <place-order :isSalesAgent="true"/>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style>
</style>